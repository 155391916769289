<div class="px-4 py-5">
  <h3 class="mb-4">Contact Us</h3>
  <ng-container *ngFor="let contact of contacts">
    <div>
      <img
        [src]="contact.flag_source"
        alt="contact.title"
        class="me-2"
        style="margin-top: -6px; width: 24px"
      />
      <h4 class="d-inline">{{ contact.title }}</h4>
      <p *ngIf="contact.subtitle" class="pt-2">{{ contact.subtitle }}</p>
      <p *ngIf="!contact.registration.email" class="pt-2 fw-bolder">
        {{ contact.registration.message }}
      </p>
      <p *ngIf="contact.registration.email">
        {{ contact.registration.message }} Email:
        <a [href]="mailto(contact.registration.email)" target="_blank">{{
          contact.registration.email
        }}</a>
      </p>
      <p *ngIf="contact.registration.phone">
        Phone: {{ contact.registration.phone }}
      </p>
      <p *ngIf="!contact.claim.email" class="pt-2 fw-bolder">
        {{ contact.claim.message }}
      </p>
      <p *ngIf="contact.claim.email">
        {{ contact.claim.message }} Email:
        <a [href]="mailto(contact.claim.email)" target="_blank">{{
          contact.claim.email
        }}</a>
      </p>
      <p *ngIf="contact.claim.phone">Phone: {{ contact.claim.phone }}</p>
    </div>
  </ng-container>
</div>
