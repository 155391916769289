import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { ContactInfo, MFR_PROGRAM } from './contact-us.types';
import { CONTACT_US } from './contact-us.constants';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  constructor(private authService: AuthenticationService) {}

  mailto(email: string) {
    return `mailto:${email}?subject="Contact Us"`;
  }

  private get user() {
    return this.authService.user;
  }

  public get contacts(): ContactInfo[] {
    const mfrProgram = this.authService.currentMfrProgram?.id;
    switch (mfrProgram) {
      case MFR_PROGRAM.EPG:
        return CONTACT_US['ppsp'];
      case MFR_PROGRAM.CNH:
        return CONTACT_US['cnh'];
      case MFR_PROGRAM.PPSP:
        return CONTACT_US['ppsp'];
      default:
        return CONTACT_US['epg'];
    }
  }

  public get currentMfrProgram(): string {
    if (this.user == null) return 'EPG';
    const mfrProgram = this.authService.currentMfrProgram;
    return mfrProgram?.name ?? 'EPG';
  }

  public get userCountry() {
    return this.authService.user;
  }
}
