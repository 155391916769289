import { Component, ContentChildren, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationError, NavigationStart, ResolveEnd, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { Observable, of, Subscription, timer } from 'rxjs';
import { OnlineService } from './core/utils/online.service';
import { LoadingScreenService } from './shared/services/loading-screen/loading-screen.service';
import {
    trigger,
    style,
    transition,
    animate,
    query,
} from "@angular/animations";
import { delay, filter, map, mergeMap, repeat, switchMap, tap } from 'rxjs/operators';
import { NavigationService } from './core/services/navigation/navigation.service';
import { registerLocaleData } from '@angular/common';
// import * as LogRocket from 'logrocket';
import { User } from './shared/models/User';
import * as LogRocket from 'logrocket';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { OktaAuthStateService } from '@okta/okta-angular';
import { SiteTitleService } from './shared/services/site-title.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginFormComponent } from './shared/components/login-form/login-form.component';
import { LocaleService } from './LocaleService';
import { AuthenticationService } from './modules/authentication/authentication.service';
export const NAVIGATION_COOKIE = 'NavigationHistory';

enum ThemeStyle {
    ESPP = 'theme-assurant.css',
    EPG = 'theme-assurant.css',
    CNH = 'theme-cnh.css',
    PPSP = 'theme-ppsp.css',
    BOMAG = 'theme-bomag.css',
    ASSURANT = 'theme-assurant.css',
    DYNAPAC = 'theme-dynapac.css'
}

enum Favicon {
    EPG = 'assurant-favicon.ico',
    CNH = 'cnh-favicon.ico'
}


export const slideInAnimation =
    trigger('routeAnimations', [
        transition('* => *', [

            query(':enter',
                [
                    style({ opacity: 0 })
                ],
                { optional: true }
            ),

            query(':leave',
                [
                    style({ opacity: 1 }),
                    animate('0.01s', style({ opacity: 0 }))
                ],
                { optional: true }
            ),

            query(':enter',
                [
                    style({ opacity: 0 }),
                    animate('0.2s', style({ opacity: 1 }))
                ],
                { optional: true }
            )

        ])
    ]);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [slideInAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
    title = '';
    onlineSubscription: Observable<boolean> = new Observable<boolean>();
    navigationSubscrition = new Subscription();
    userSubscription = new Subscription();
    locale: string = 'en-US';

    constructor(
        private authService: AuthenticationService,
        private connectionSerive: OnlineService,
        private loadingScreenService: LoadingScreenService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: SiteTitleService,
        private dialog: MatDialog,
        @Inject(DOCUMENT) private document: Document,
        private okta: OktaAuthStateService,
        private localeService: LocaleService) {
            this.loadFavicon();
        this.loadStyle();
    
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data &&    child.snapshot.data['title']) {
                        return child.snapshot.data['title'];
                    } else {
                        return null;
                    }
                }
                return null;
            })
        )
        .subscribe( (data: any) => {
            // this.siteTitle.setTitle('test');
            this.titleService.setTitle(data);
        });
    }
    public thedomain(domain) {
        //const urlParts = new URL(window.location.href).hostname.split('.');
        // console.log(urlParts
        //     .slice(0)
        //     .slice(-(urlParts.length === 4 ? 3 : 2))
        //     .join('.'))
        var subdomain = 'www.', subdomain_len = subdomain.length;
        if (domain.substr(subdomain, subdomain_len) == subdomain)
          domain = domain.substring(subdomain_len)
        return domain;
    }
    public get themeName(){
        const hostname = this.thedomain(window.location.hostname);
        //hosts
        switch(hostname){

            case 'dev-ppp.epgins.com':
            case 'espp-integration-model.epgins.com':
            case 'ppp.cnhinsurance.com':
                return ThemeStyle.CNH;
            case 'dev-ppsp.epgins.com':
                return ThemeStyle.PPSP;
            case 'model-bomag.espp.com':
                return ThemeStyle.BOMAG;
            case 'dev-bomag.epgins.com':
                return ThemeStyle.DYNAPAC;

            default:
                return ThemeStyle.ESPP
        }
    }

    public get favicon(){
        const hostname = this.thedomain(window.location.hostname);
        
        switch(hostname){
            case 'dev-ppp.epgins.com':
            case 'espp-integration-model.epgins.com':
            case 'ppp.cnhinsurance.com':
                return Favicon.CNH;
            default:
                return Favicon.EPG;
        }
    }

    loadFavicon() {
        let styleName = this.favicon
        const head = this.document.getElementsByTagName('head')[0];

        //https://juristr.com/blog/2019/08/dynamically-load-css-angular-cli/
        const style = this.document.createElement('link');
        style.rel = 'image/x-icon';
        style.id = 'client-favicon';
        style.rel = 'icon';
        style.href = `./assets/favicons/${styleName}`;

        head.appendChild(style);
    }

    loadStyle() {
        const params = new URLSearchParams(window.location.search);
        const themeOverwrite = params.get('theme')
        let styleName = this.themeName
        const head = this.document.getElementsByTagName('head')[0];

        let themeLink = this.document.getElementById(
            'client-theme'
        ) as HTMLLinkElement;

        if(themeOverwrite && (themeOverwrite.toUpperCase()) in ThemeStyle){
           styleName = ThemeStyle[themeOverwrite.toUpperCase()];
        }

        //https://juristr.com/blog/2019/08/dynamically-load-css-angular-cli/
        const style = this.document.createElement('link');
        style.id = 'client-theme';
        style.rel = 'stylesheet';
        style.href = `${styleName}`;

        head.appendChild(style);
    }

    ngOnDestroy(): void {
        this.navigationSubscrition.unsubscribe();
        this.userSubscription.unsubscribe();
    }

    ngOnInit(): void {
        // this.authService.autoLogin();
        this.onlineSubscription = this.connectionSerive.isOnline();

        // const poll = this.okta.authState$.pipe(
        //     filter(tokens => tokens.accessToken !== undefined),
        //     filter(_ => !this.authService.storedUser),
        //     mergeMap(_ => this.authService.getUserData()),
        //     tap((d) => console.log(d)),
        //     delay(60000*10),
        //     repeat()
        //   );

        //   poll
        //     .subscribe(user => {
        //         console.log(user)
        //         this.authService.user.next(user);
        //         this.authService.storeUser(user);
        //     });

        // timer(1,60000)
        // .pipe(
        //     switchMap( interval => this.okta.authState$),
        //     filter(tokens => {
        //         return (tokens.accessToken !== undefined &&  !('refreshToken' in tokens))
        //     }),
        //     map(tokens => {
        //         if(tokens.accessToken){
        //             const expiresAt = tokens.accessToken.expiresAt * 1000;
        //             const seconds =  (expiresAt - Date.now())/1000;
        //             return {loggedIn: true, remainingSeconds: seconds};
        //         }
        //         return {loggedIn: false, remainingSeconds: 0};
        //     })
        // )
        // .subscribe(tokens => {
        //     //console.log(tokens)
        //    if(!tokens.loggedIn){
        //     //console.log('need to reauth')
        //    }

        //    if(tokens.loggedIn && tokens.remainingSeconds < (60*10)){
        //         const loginForm = this.dialog.openDialogs.find( ref => ref.componentInstance instanceof LoginFormComponent);
        //         if (loginForm) return;
        //         this.dialog.open(LoginFormComponent,{disableClose: true, closeOnNavigation: false})
        //    }
        // })

        
        // if (window.location.hostname !== "localhost") {
        // LogRocket.init('jdmknw/espp');
        // const localData = localStorage.getItem('user');
        // if (localData) {
        //     const userData: User = JSON.parse(localData);
        //     LogRocket.identify(''+userData.email, {
        //         name: `${userData.first_name} ${userData.last_name}`,
        //         email: userData.email,
        //         domain: window.location.host
        //       });
        //  }
        // }

        this.router.events.subscribe((event: any): void => {
            if (event instanceof NavigationEnd) {
                  const params = new URLSearchParams(window.location.search);
                  const theme = params.get('theme');
                  
                //   if(theme){
                //     this.router.navigate([], {
                //         queryParams: {
                //           theme: theme
                //         },
                //         queryParamsHandling: 'merge',
                //       });
                //   }
             }
        })

    }

    //@angular/common/locales/en
    //https://stackblitz.com/edit/angular-dynamic-locale-kev3gy?file=src%2Fapp%2Fhome.component.ts
    //https://medium.com/angular-in-depth/dynamic-import-of-locales-in-angular-b994d3c07197
    private localeInitializer(localeId: string): Promise<any> {
        return import(
            /* webpackInclude: /(en|en-US|en-NZ)\.js$/ */
            `@angular/common/locales/${localeId}.js`
        ).then(module => registerLocaleData(module.default));
    }

    // Shows and hides the loading spinner during RouterEvent changes
    navigationInterceptor(event): void {
        if (event instanceof NavigationStart) {
            this.loadingScreenService.startLoading();
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationEnd) {
            this.loadingScreenService.stopLoading();
        }
        if (event instanceof NavigationError) {
            this.loadingScreenService.stopLoading();
        }
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }

    private getUsersLocale(): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return 'en-US';
        }
        const wn = window.navigator as any;
        let lang = wn.languages ? wn.languages[0] : 'en-US';
        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
        return lang;
    }
}
