export enum MFR_PROGRAM {
  EPG = 1,
  CNH = 2,
  PPSP = 3,
}

export type ContactUsInfo = {
  [$key: string]: ContactInfo[];
};

export type ContactInfo = {
  title: string;
  flag_source: string;
  subtitle?: string;
  registration: SupportInfo;
  claim: SupportInfo;
};

type SupportInfo = {
  message: string;
  phone?: string;
  email?: string;
};
